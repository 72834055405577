import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'

const OverviewGroup = styled.div`
    width: 960px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    justify-items: stretch;

    & a {
        color: #333333;
        font-weight: 700;
        line-height: 48px;
        ${'' /* background: #333333; */}
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 3px 3px;        
    }
`

const OverviewEle = styled.div`
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    & p {
        margin: 0;
    }
`

const Line = styled.div`
    background: #333333;
    height: 4px;
    width: 80%;
    margin: 2px 0 10px 0;
`

const ProjectOverview = props => (
    <OverviewGroup>
        <OverviewEle>
            <p><em>UX</em></p>
            <Line />
            <li><Link to="/#apps">Apps • Websites ></Link></li>
            <li><Link to="/ui-exercise/">UI Design Exercise ></Link></li>
            <li><a href='https://drive.google.com/file/d/1fYp6mB8BvuXlgf-ssOFzBBctOkovebSn/view?usp=sharing' target='_blank'>Usability Studies ></a></li>
        </OverviewEle>
        <OverviewEle>
            <p><em>Graphic design</em></p>
            <Line />
            <li><Link to="/404/">Typography (coming soon)</Link></li>
            <li><Link to="/404/">Drawings (coming soon)</Link></li>
        </OverviewEle>
        <OverviewEle>
            <p><em>Architecture & 3D</em></p>
            <Line />
            <li><a href="https://drive.google.com/file/d/1RE_9RaLNcIH0JgFIlEnzKi0mFQdEe9SH/view?usp=sharing" target="_blank">Architecture Design Portfolio ></a></li>
            <li><Link to="404">VR/AR Games (coming soon)</Link></li>
        
        </OverviewEle>
    </OverviewGroup>
)


export default ProjectOverview