import React from "react"
// import { Link } from 'gatsby';

import SEO from "../components/seo"
import ProjectCard from "../components/ProjectCard";
import ProjectOverview from "../components/ProjectOverview";
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="HomeHero">
      <h6>Hi there! <br/> I'm Lori, a UX designer :)</h6>
      <div className="ProjectOverview">
          <p><br /></p>
          <ProjectOverview />

        </div>
    </div>
    <div className="ProjectCardGroup">
        <div className="ProjectCards" id="apps">
          <p><br /></p>
          <h3><em>Apps • Websites</em></h3>

          <ProjectCard
            link="/gsa-online-portal/" 
            title="GSA Online Portal"
            tagline="Empower Amazon's Loss Prevention people better leverage their tools and resources"
            tag="Web App • Capstone Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/gsa-online-portal.png')}
          />


          <ProjectCard 
            link="/gaido-health/"
            title="Gaido Health"
            tagline="Balance user needs and data needs in an early-stage AI system"
            tag="Mobile App • Internship Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/bcgdv.jpg')}
            />

          {/* <ProjectCard 
            link="/shopler/"
            title="Shopler"
            tagline="Oversea shopping-helping made easy"
            tag="Mobile App • Passion Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/shopler.png')}
            /> */}
            
            <ProjectCard 
            link="/start/"
            title="START"
            tagline="Streamline the car booking experience for a peer-to-peer car-sharing platform"
            tag="Mobile App • Internship Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/start.png')}
            />

          <ProjectCard 
            link="/fastback-studios/"
            title="Fastback Studios"
            tagline="Help a recording studio connect with professional musicians"
            tag="Website • Course Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/fastback.png')}
            />



       </div>




    </div>
    
    
  </Layout>
)

export default IndexPage